
html {
    -webkit-text-size-adjust: 100%; 
}

.About {
  min-height: 85vh;
  padding: 0rem 2rem;
}

.About--title {
  font-family: 'Fuzzy Bubbles', cursive;
  font-size: 2rem;
  margin: 10px 0px;
}

.About--image-box {
  width: 100%;
  text-align: center;
}

.About--image {
  height: 20rem;
  border: 1px solid black;
  border-radius: 50%;
  filter: grayscale(100%);
  transition: filter 0.3s ease;
}

.About--image:hover {
  filter: grayscale(0%);
}

.About--name {
  font-family: 'Fuzzy Bubbles', cursive;
  font-size: 1.2rem;
  text-align: center;
}

.About--text {
  font-family: 'Courier Prime', monospace;
  font-size: 1.1rem;
  letter-spacing: -0.05em;
  line-height: 1.4; 
  margin-bottom: 0rem;
}

.About--subgroup ul {
  padding-left: 1.5rem;
  list-style-position: outside;
  list-style-type: disc;
}

.About--subgroup li {
  text-indent: -0.5rem;
  align-items: baseline;
}

.emoji {
  vertical-align: middle;
  font-size: 0.9em;
  margin: 0 2px;
  line-height: 1;
}

.About--side-heading {
  font-family: 'Fuzzy Bubbles', cursive;
  font-size: 1.2rem;
  font-weight: 600;
}

.About--work {
  font-family: 'Courier Prime', monospace;
  font-size: 1.1rem;
}

.About--work-item {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 4px;
}

.About--work-item-company {
  margin: 0px;
  padding-right: 20px;
  font-weight: 600;
}

.About--work-item-position {
  font-style: italic;
  font-size: 0.9rem;
  margin: 0px;
}

.About--work-item-description {
  font-size: 1rem;
  margin: 0px;
  list-style-type: disc;
  margin-left: 1.5rem;
  margin-bottom: 20px;
}

.About--work-item-duration {
  margin: 0px;
}

.About--skills {
  font-family: 'Courier Prime', monospace;
  list-style-type: none;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.About--skills-item {
  padding: 5px 10px;
  border: 1px solid #000000;
  border-radius: 5px;
}

.About--education {
  font-family: 'Courier Prime', monospace;
  font-size: 1.1rem;
}

.About--education-item {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  margin-bottom: 20px;
}

.About--education-item-institute {
  margin: 0px;
  font-weight: 600;
}

.About--education-item-standard {
  font-style: italic;
  font-size: 0.9rem;
  margin: 0px;
}

.About--education-item-duration {
  margin: 0px;
}

@media screen and (max-width: 900px) {
  .About--image {
    height: 16rem;
  }
  
  .About--title {
    font-size: 1.5rem;
  }

  .About--text {
    line-height: 1.3;
  } 

  .emoji {
    font-size: 0.9em;
  }

  .About--work {
    font-size: 1.1rem;
  }

  .About--work-item {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 4px;
    gap: 0px;
  }

.About--work-item-description {
  font-size: 1rem;
  margin: 0px;
  list-style-type: disc;
  margin-left: 1.5rem;
  margin-bottom: 20px;
}

  .About--education {
    font-size: 1.1rem;
  }

  .About--education-item {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 20px;
    gap: 0px;
  }
}
