.App {
  max-width: 1200px;
  min-width: 340px;
  margin: 0px auto;
  height: 100vh;
  /* border: 1px solid black; */
}

.Navbar {
  padding: 0rem 2rem;
  border-bottom: 1px solid black;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-family: 'Gloria Hallelujah', cursive;
  font-size: 1.2rem;
  text-decoration: none;
  color: black;
}

.menu {
  max-width: 1200px;
  min-width: 340px;
} 

ul {
  padding: 0;
  margin: 0;
}

.menu-list {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  list-style-type: none;
}

.menu-item{
  font-family: 'Courier New', Courier, monospace;
  font-size: 2rem;
  position: relative;
  color: #000;
  text-decoration: none;
}

.menu-item:after {    
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  height: 2px;
  left: 50%;
  position: absolute;
  background: #000;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.menu-item:hover:after { 
  width: 100%; 
  left: 0; 
}

.close-logo {
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem 2rem;
}

.Footer {
  padding: 0rem 2rem;
  border-top: 1px solid black;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.social-icons {
  height: 50px;
  gap: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.copyrights {
  font-family: 'Courier New', Courier, monospace;
}

@media screen and (min-width:900px) {
  .logo:hover {
    font-size: 0;
    color:steelblue;
  }
  
  .logo:hover::after {
    content: attr(data-hover);
    font-size: 1.2rem;
  }
}