.contact {
    min-height: 85vh;
    padding: 0rem 2rem;
}

.contact--title{
    font-family: 'Fuzzy Bubbles', cursive;
    font-size: 2rem;
    margin: 10px 0px;
}

.contact--box-field {
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.contact--box {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.contact--image {
    width: 22rem
}

.contact--form {
    width: 28rem
}

.contact--label {
    font-family: 'Courier Prime', monospace;
    font-size: 1.1rem;
}

.contact--input-field {
    padding: 0px;
    width: 100%;
    height: 2rem;
    margin: 0 0 10px 0;
}

.contact--message {
    padding: 0px;
    width: 100%;
    height: 5rem;
    margin: 0 0 10px 0;
}

.contact--submit {
    color: #000000;
    background-color: #ffffff;
    border: 1px solid #000000;
    border-radius: 5px;
    padding: 6px 15px;
    cursor: pointer;
    font-size:14px;
    transition: all 0.2s ease-in-out;
    font-family: 'Courier Prime', monospace;
    text-decoration: none;
    margin-bottom: 20px;
}



@media screen and (max-width:900px) {

    .contact{
        height: auto;
    }

    .contact--box-field{
        height: auto;
    }

    .contact--box {
        display: block;
        margin: 0px auto;
    }
    
    .contact--image {
        width: 20rem;
        margin-bottom: 20px;
    }
    
    .contact--form {
        width: 18rem
    }

    .contact--input-field {
        margin: 0 0 5px 0;
    }

    .contact--message {
        margin: 0 0 5px 0;
    }

    .contact--title{
        font-size: 1.5rem;
    }
    
}

@media screen and (min-width:900px) {
    .contact--submit:hover {
        box-shadow: 1px 1px #000000, 2px 2px #000000, 3px 3px #000000;
        transform: translateX(-3px);
    }
}